import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IconFail, IconSuccess } from '../../components/icons';
import { ButtonStep1, iconSocialNetwork } from '../ConnectPage';


const ResultConnect = () => {
  const classes = useStyles();

  const params: any = useParams();

  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState<any>('');

  useEffect(() => {
    const error = location.search.split('error=')[1];
    setErrorMessage(decodeURI(error))
  }, [])
  return (
    <div className={classes.root}>
      {params.status === 'success' ? (
        <IconSuccess />
      ) : (
        <IconFail />
      )}

      <p className={classes.title}>
        <div>{iconSocialNetwork[params.social].title}</div>
        {params.status === 'success'
          ? 'was successfully connected!'
          : 'connection failed!'}
      </p>
      <div className={classes.box}>
        {
          iconSocialNetwork[params.social].icon
        }

        {params.status === 'success' ? (
          <p>Bis Dev's'</p>
        ) : (
          <>
            <p className={classes.textDescription}>

              {`${errorMessage && errorMessage}! We’re sorry, please try again`}
            </p>
          </>
        )}
      </div>
      <ButtonStep1 onClick={params.status === 'success' ? () => window.location.replace('https://auth.discover.instalent.dev/auth-success') : () => window.location.replace('https://auth.discover.instalent.dev/auth-failed')}>
        {params.status === 'success' ? 'Next' : 'Try Again'}
      </ButtonStep1>
    </div>
  );
};

export default ResultConnect;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '100%',
    minHeight: '100vh',
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      margin: '0px 20px',
    }
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E9E9E9',
    width: ' 100%',
    padding: '30px 13px 0px 13px',
    backgroundColor: '#FCFCFC',
    borderRadius: 16,
    boxSizing: 'border-box',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '32px',
    color: '#000000',
    textAlign: 'center',
  },
  textDescription: {
    color: '#FF4431',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    backgroundColor: '#fceae9',
    width: '100%',
    padding: '8px 0',
    borderRadius: 6,
  },
}));
