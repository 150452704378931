const HomeLayout = ({ children }: any) => {
  return (
    <div style={{
      background: 'linear-gradient(136.86deg, rgba(103, 58, 183, 0.8) 4.09%, rgba(49, 27, 146, 0.8) 97.5%)',
      height: '100%',
      minHeight: '100vh'
    }}>
      {children}
      <footer style={{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 16
      }}>
        <a href="https://www.discovertech.io/privacy-policy" target="_blank" style={{
          color: '#ffff',
          marginRight: 16,
          textDecoration: 'none',
          fontWeight: 600
        }}>Terms of Service</a>
        <a href="https://www.discovertech.io/privacy-policy" target="_blank" style={{
          color: '#ffff',
          textDecoration: 'none',
          fontWeight: 600
        }}>Privacy Policy</a>
      </footer>
    </div>
  )
}

export default HomeLayout