import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { Routes, Route } from 'react-router-dom'
import ConnectPage from './container/ConnectPage';

import HomePage from './container/HomePage';
import ResultConnect from './container/ResultConnect';
import AuthPage from './container/AuthPage';
const theme = createTheme();

declare const window: any;

function App() {

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '658211179236756',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v11.0'
      });
    };
    (function (d, s, id) {
      var js: any, fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>    <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/connect/:social' element={<ConnectPage />} />
        <Route path='/result/:status/:social' element={<ResultConnect />} />
        <Route path='/auth/:social' element={<AuthPage />} />
      </Routes></ThemeProvider>
    </StylesProvider>

  );
}

export default App;
