import { Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: 'calc(100vh - 34px)'
  },
  iconLogoHome: {
    width: 465,
    height: 142,
    [theme.breakpoints.down('md')]: {
      width: 283,
      height: 142
    }
  },
  containerLeft: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  textTitle: {
    margin: '40px 0px 90px 0px',
    color: '#ffff',
    fontSize:  48,
    [theme.breakpoints.down('md')]: {
      marginBottom: 36,
      fontSize: 20
    }
  },
  containerStore: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  iconStore: {
    marginRight: 16,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginBottom: 16
    }
  },
  containerRight: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    height: '100%',
  },
  iconPhone: {
    display: 'flex',
    margin: 'auto',
    height: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  iconMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      margin: 'auto'
    }
  }
}));

export { useStyles }