import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { Box, CircularProgress } from "@mui/material";
declare const window: any;

const AuthPage = () => {

  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const params: any = useParams();

  const getAccessTokenInstagram = async (code: string) => {
    const token = localStorage.getItem('token');
    const profileId = localStorage.getItem('profileId');

    try {
      const {
        data: {
          expiresIn,
          tokenLong: {
            access_token,
          },
          userId
        },
      } = await axios.get(`${process.env.REACT_APP_BASE_URL}social-network/accessToken/instagram?type=mobile&code=${code}`);

      if (expiresIn && access_token && userId) {
        const { data: { data: {
          id
        } } } = await axios.post(`${process.env.REACT_APP_BASE_URL}social-network`, {
          "profileId": profileId,
          "type": 'instagram',
          "authToken": access_token,
          "authRefreshToken": access_token,
          "userSocialId": userId,
          "expirationDate": expiresIn
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}social-profile/instagram?social_id=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        await axios.post(`${process.env.REACT_APP_BASE_URL}social-profile`, {
          "socialId": id,
          "nickName": data.username,
          "accountType": data.account_type
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      navigate('/result/success/instagram')
    } catch (error: any) {
      console.log('error', error.res);
      setErrorMessage(error?.response?.data?.message)
      navigate(`/result/fail/instagram?error=${error?.response?.data?.message}`)
    }
  }

  const getAccessTokenFb = async (code: string) => {
    const token = localStorage.getItem('token');
    const profileId = localStorage.getItem('profileId');

    try {

      const res = await axios.get(`https://graph.facebook.com/v15.0/oauth/access_token?client_id=658211179236756&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}/auth/facebook&client_secret=92abf8c3c0b879476c41b59b7c61ae2a&code=${code}`);

      const {
        data: {
          access_token,
          expires_in,
          id,
        }
      } = await axios.get(`${process.env.REACT_APP_BASE_URL}social-network/accessToken/facebook?type=mobile&access_token=${res.data.access_token}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (expires_in && access_token && id) {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}social-network?type=mobile`, {
          "profileId": profileId,
          "type": 'facebook',
          "authToken": access_token,
          "authRefreshToken": access_token,
          "userSocialId": id,
          "expirationDate": expires_in
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const profile = await axios.get(`${process.env.REACT_APP_BASE_URL}social-profile/facebook?type=mobile&social_id=${res.data.data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        await axios.post(`${process.env.REACT_APP_BASE_URL}social-profile?type=mobile`, {
          "socialId": res.data.data.id,
          "nickName": profile.data.name,
          "firstName": profile.data.first_name,
          "lastName": profile.data.last_name,
          "email": profile.data.email,
          "avatar": profile.data.picture.data.url,
          "friends": profile.data.total_friends
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      navigate('/result/success/facebook')
    } catch (error: any) {
      console.log(error);
      navigate(`/result/fail/facebook?error=${error?.response?.data?.message}`)
    }
  }

  const getAccessTokenTwitter = async (code: string) => {
    const stateCode = location.search.split('state=')[1].split('&code=')[0];
    const isTwitter = localStorage.getItem('isTwitter');

    try {
      const {
        data: {
          state,
          profileId,
          codeVerifier,
          accessToken,
          type
        },
      } = await axios.get(`${process.env.REACT_APP_BASE_URL}auth-twitter/state`, {
        params: {
          state: stateCode,
        }
      });
      const {
        data: {
          expires_in,
          access_token,
          refresh_token,
          data
        },
      } = await axios.get(`${process.env.REACT_APP_BASE_URL}social-network/accessToken/twitter?type=mobile`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: {
          code,
          state,
          codeVerifier,
        }
      });
      console.log('Check isTwitter', isTwitter);

      if (access_token && expires_in) {
        if (type) {
          await axios.post(`${process.env.REACT_APP_BASE_URL}auth-social-network/create_auth_twitter`, {
            "authToken": access_token,
            "authRefreshToken": refresh_token,
            "userId": data.id,
            "profileId": profileId,
            "type": "twitter"
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
        }

        else {
          const res = await axios.post(`${process.env.REACT_APP_BASE_URL}social-network`, {
            "profileId": profileId,
            "type": 'twitter',
            "authToken": access_token,
            "authRefreshToken": refresh_token,
            "userSocialId": data.id,
            "expirationDate": expires_in
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });

          const profile = await axios.get(`${process.env.REACT_APP_BASE_URL}social-profile/twitter?social_id=${res.data.data.id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          await axios.post(`${process.env.REACT_APP_BASE_URL}social-profile`, {
            "socialId": res.data.data.id,
            "nickName": profile.data.data.name,
            "followers": profile.data.data.public_metrics.followers_count,
            "avatar": profile.data.data.profile_image_url,
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
        }

      }
      localStorage.removeItem('isTwitter');
      navigate('/result/success/twitter')
    } catch (error: any) {
      console.log(error);
      setErrorMessage(error?.response?.data?.message)
      localStorage.removeItem('isTwitter');
      navigate(`/result/fail/twitter?error=${error?.response?.data?.message}`)
    }
  }

  const getAccessTokenYoutube = async (code: string) => {
    const token = localStorage.getItem('token');
    const profileId = localStorage.getItem('profileId');
    try {
      const { data: {
        access_token,
        channel_id,
        expiry_date,
        id_token,
        refresh_token,
        scope,
        token_type
      } } = await axios.get(`${process.env.REACT_APP_BASE_URL}social-network/accessToken/youtube?type=mobile`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          code,
        }
      });

      if (access_token && expiry_date) {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}social-network`, {
          "profileId": profileId,
          "type": 'youtube',
          "authToken": access_token,
          "authRefreshToken": refresh_token,
          "userSocialId": channel_id,
          "expirationDate": new Date(expiry_date).toISOString()
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const profile = await axios.get(`${process.env.REACT_APP_BASE_URL}social-profile/youtube?social_id=${res.data.data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (profile?.data?.kind?.includes('channel')) {
          await axios.post(`${process.env.REACT_APP_BASE_URL}social-profile`, {
            "socialId": res.data.data.id,
            "friends": profile.data.items[0]?.statistics?.videoCount,
            "followers": profile.data.items[0]?.statistics?.subscriberCount,
            "nickName": profile.data.items[0]?.snippet?.title,
            "avatar": profile.data.items[0]?.snippet?.thumbnails.medium.url,
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
        }

        else {
          await axios.post(`${process.env.REACT_APP_BASE_URL}social-profile`, {
            "socialId": res.data.data.id,
            "nickName": profile.data.name,
            "avatar": profile.data.picture,
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
        }
      }
      navigate('/result/success/youtube')
    } catch (error: any) {
      console.log('error', error);
      navigate(`/result/fail/youtube?error=${error?.response?.data?.message}`)
      setErrorMessage(error?.response?.data?.message)
    }
  }

  const getAccessTokenTiktok = async (code: string) => {
    const token = localStorage.getItem('token');
    const profileId = localStorage.getItem('profileId');
    try {
      const {
        data: {
          data: {
            access_token,
            expires_in,
            refresh_token,
            open_id
          }
        }
      } = await axios.get(`${process.env.REACT_APP_BASE_URL}social-network/accessToken/tiktok?type=mobile`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          code,
        }
      });


      const today = new Date();
      const expiry_date = today.setHours(today.getHours() + 23);

      if (access_token && expires_in) {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}social-network`, {
          "profileId": profileId,
          "type": 'tiktok',
          "authToken": access_token,
          "authRefreshToken": refresh_token,
          "userSocialId": open_id,
          "expirationDate": new Date(expiry_date).toISOString()
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const profile = await axios.get(`${process.env.REACT_APP_BASE_URL}social-profile/tiktok?social_id=${res.data.data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        await axios.post(`${process.env.REACT_APP_BASE_URL}social-profile`, {
          "socialId": res.data.data.id,
          "followers": profile.data.user.follower_count,
          "nickName": profile.data.user.display_name,
          "avatar": profile.data.user.avatar_url,
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      navigate('/result/success/tiktok')
    } catch (error: any) {
      console.log('error', error);
      setErrorMessage(error.message)
      navigate(`/result/fail/tiktok?error=${error?.response?.data?.message}`)
    }
  }

  useEffect(() => {
    if (params.social === 'facebook') {
      const code = location.search.split('&code=')[1];
      localStorage.setItem('code', code);
      getAccessTokenFb(code)
    }

    if (params.social === 'instagram') {
      const code = location.search.split('=')[1].split('&')[0];
      localStorage.setItem('code', code);
      getAccessTokenInstagram(code)
    }

    if (params.social === 'twitter') {
      const code = location.search.split('code=')[1];
      localStorage.setItem('code', code);
      getAccessTokenTwitter(code)
    }

    if (params.social === 'youtube') {
      const code = location.search.split('code=')[1].split('&scope')[0];
      localStorage.setItem('code', code);
      getAccessTokenYoutube(code)
    }



    console.log('params.social', params.social);
    if (params.social === 'tiktok') {
      console.log('____', params.social);
      const code = location.search.split('code=')[1].split('&scope')[0];
      localStorage.setItem('code', code);
      getAccessTokenTiktok(code)
    }
  }, [])

  return (

    <Box sx={{ width: '100vw', height: '100vh', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  )
}

export default AuthPage
