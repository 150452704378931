import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IconLogo } from "../../components/icons"

const MainLayout = ({ children }: any) => {
  const classes = useStyles()
  return (
    <div>
      <header className={classes.header}>
        <img src="/logoIcon.svg" alt="" className={classes.iconLogoHome} />
      </header>
      {children}
    </div>
  )
}

export default MainLayout;

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    background: 'linear-gradient(136.86deg, rgba(103, 58, 183, 0.8) 4.09%, rgba(49, 27, 146, 0.8) 97.5%); border- radius: 0px 0px 32px 32px',
    width: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 65,
    paddingBottom: 40,
    borderRadius: '0px 0px 32px 32px'
  },
  iconLogoHome: {
    width: 465,
    height: 50,
    [theme.breakpoints.down('md')]: {
      width: 283,
      height: 50
    }
  },
}));
