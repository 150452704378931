import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconFacebook, IconInstagram, IconTikTok, IconTwitter, IconYouTube } from '../../components/icons';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';

declare const window: any;

export const iconSocialNetwork: any = {
  tiktok: {
    title: 'TikTok',
    icon: <IconTikTok />,
    url: 'tiktok'
  },
  facebook: {
    title: 'FaceBook',
    icon: <IconFacebook />,
    ulr: 'facebook',

  },
  youtube: {
    title: 'YouTube',
    icon: <IconYouTube />,
    url: 'youtube'
  },
  twitter: {
    title: 'Twitter',
    icon: <IconTwitter />,
    url: 'twitter'
  },
  instagram: {
    title: 'Instagram',
    icon: <IconInstagram />,
    url: 'instagram',
  }
}

const ConnectPage = () => {
  const classes = useStyles();

  const params: any = useParams();

  const location = useLocation();

  const [userName, setUserName] = useState('')

  const navigate = useNavigate();

  const iconSocialNetwork: any = {
    tiktok: {
      title: 'TikTok',
      icon: <IconTikTok />,
      url: 'tiktok',
      login: async () => {
        const token = location.search?.split('access_token=')[1].split('&childName=')[0];
        const url = `${process.env.REACT_APP_BASE_URL}social-network/tiktok?type=mobile`;
        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        window.location.replace(data.url);
      }
    },
    facebook: {
      title: 'FaceBook',
      icon: <IconFacebook />,
      ulr: 'facebook',
      login: async () => {

        const token = location.search?.split('access_token=')[1].split('&childName=')[0];
        const url = `${process.env.REACT_APP_BASE_URL}social-network/facebook?type=mobile`;
        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        window.location.replace(data);
      },
      logout: async () => {
        window.open("https://www.facebook.com/");
      }
    },
    youtube: {
      title: 'YouTube',
      icon: <IconYouTube />,
      url: 'youtube',
      login: async () => {
        const token = location.search?.split('access_token=')[1].split('&childName=')[0];
        const url = `${process.env.REACT_APP_BASE_URL}social-network/youtube?type=mobile`;

        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        window.location.replace(data.url);
      },
      logout: async () => {
        window.open("https://youtube.com/");
      }
    },
    twitter: {
      title: 'Twitter',
      icon: <IconTwitter />,
      url: 'twitter',
      login: async () => {
        const isTwitter = localStorage.getItem('isTwitter');
        const token = location.search?.split('access_token=')[1].split('&childName=')[0];
        const profileId = location.search?.split('profileId=')[1]?.split('&access_token=')[0];
        const url = `${process.env.REACT_APP_BASE_URL}social-network/twitter?type=mobile`;
        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        await axios.post(`${process.env.REACT_APP_BASE_URL}auth-twitter`, {
          "profileId": profileId,
          "state": data.state,
          "codeVerifier": data.codeVerifier,
          "accessToken": token,
          "type": isTwitter
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        window.location.replace(data.url);
      },
      logout: async () => {
        window.open("https://twitter.com/logout");
      }
    },
    instagram: {
      title: 'Instagram',
      icon: <IconInstagram />,
      url: 'instagram',
      login: () => {
        const url = `https://api.instagram.com/oauth/authorize?client_id=6173530185994864&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}/auth/instagram&scope=user_profile,user_media&response_type=code`;
        window.location.replace(url);
      }
      , logout: () => {
        window.open("https://instagram.com/accounts/logout");
      }
    }
  }

  useEffect(() => {
    const token = location.search?.split('access_token=')[1].split('&')[0];
    const profileId = location.search?.split('profileId=')[1]?.split('&access_token=')[0];
    const isTwitter = location.search?.split('type=')[1]?.split('&')[0];

    localStorage.setItem('token', token);
    localStorage.setItem('profileId', profileId)
    const childName = location.search?.split('childName=')[1];
    setUserName(decodeURI(childName))
    if (isTwitter) {
      localStorage.setItem('isTwitter', isTwitter);
    }
    else {
      localStorage.removeItem('isTwitter');
    }
  }, [location.search])

  return (
    <MainLayout>
      <div className={classes.root}>
        <p className={classes.title}>
          Connect <span className={classes.titleName}>{userName}</span>  {
            iconSocialNetwork[params.social]?.title
          }
        </p>
        <div className={classes.icon}>
          {
            iconSocialNetwork[params.social]?.icon
          }
        </div>
        {
          (params.social === 'instagram' || params.social === 'twitter') && <p className={classes.textDescription}>
            You'll need to log out of your {
              iconSocialNetwork[params.social]?.title
            } account before we can add
            Bis Dev's.
          </p>
        }

        <div
          className={classes.rootBox}
        >

          {
            (params.social === 'instagram' || params.social === 'twitter') && <>
              <div className={classes.boxContain}>
                <div className={classes.boxStep}>1</div>
                <span className={classes.textStep}> Log out of {
                  iconSocialNetwork[params.social]?.title
                }</span>
              </div>

              <ButtonStep1 onClick={iconSocialNetwork[params.social]?.logout}>Logout</ButtonStep1>
              <div className={classes.boxContain}>
                <div className={classes.boxStep}>2</div>
                <span className={classes.textStep}> Enter Bis Dev' password </span>
              </div>
            </>
          }

          <ButtonStep2 onClick={iconSocialNetwork[params.social]?.login}>Connect</ButtonStep2>
          <Button sx={{
            width: '100%',
            borderRadius: 48,
            padding: '16px 0',
            marginTop: '12px',
            borderColor: '#ddd',
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '16px',
            maxWidth: 340,
            color: 'black'
          }} variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => window.location.replace('https://auth.discover.instalent.dev')} >Go back to App</Button>
        </div>
      </div>
    </MainLayout>
  );
};

export default ConnectPage;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 500,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 24px',
    },
  },
  icon: {
    marginTop: 24,
    marginBottom: 36,
  },
  title: {
    fontSize: 20,
    lineHeight: '32px',
    color: '#000000',
    fontWeight: 700,
    marginTop: 48,
  },
  titleName: {
    color: '#673AB7',
  },
  textDescription: {
    textAlign: 'center',
    color: '#000000',
    fontSize: 14,
    lineHeight: '20px',
    backgroundColor: '#E9E9E9',
    padding: '8px 22px',
    borderRadius: 6,
  },
  boxStep: {
    border: '1px solid #000000',
    borderRadius: '100%',
    width: 24,
    height: 24,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
  },
  textStep: {
    fontSize: 14,
    fontWeight: 700,
    color: '#000000',
    lineHeight: '16px',
    marginLeft: 8,
  },
  boxContain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnStep1: {
    width: '100%',
    borderRadius: 48,
    padding: '16px 0',
    margin: '30px 0px',
    backgroundColor: '#673AB7',
  },
  btnStep2: {
    width: '100%',
    borderRadius: 48,
    padding: '16px 0',
    margin: '30px 0px',
    borderColor: '#673AB7',
  },
  rootBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export const ButtonStep1 = (props: any) => (
  <Button
    {...props}
    sx={{
      width: '100%',
      borderRadius: 48,
      padding: '16px 0',
      margin: '30px 0px',
      backgroundColor: '#673AB7',
      color: '#ffff',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '16px',
      maxWidth: 340,
    }}
    variant="contained"
  >
    {props.children}
  </Button>
);

const ButtonStep2 = (props: any) => (
  <Button
    {...props}
    sx={{
      width: '100%',
      borderRadius: 48,
      padding: '16px 0',
      margin: '30px 0px',
      borderColor: '#673AB7',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '16px',
      maxWidth: 340,
    }}
    variant="outlined"
  >
    {props.children}
  </Button>
);
