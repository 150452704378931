import { Grid } from "@mui/material";
import { IconAppStore, IconGoogleStore, IconMobile, IconPhone } from "../../components/icons";
import HomeLayout from "../../layouts/HomeLayout";
import { useStyles } from "./styles";

const HomePage = () => {
  const classes = useStyles();
  return (
    <HomeLayout>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={classes.containerLeft} >
            <img src="./logoIcon.svg" alt="" className={classes.iconLogoHome} />
            <h2 className={classes.textTitle}>Download Our App</h2>
            <div className={classes.containerStore}>
              <IconGoogleStore className={classes.iconStore} />
              <IconAppStore />
            </div>
          </div>
        </Grid >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={classes.containerRight}>
            <IconPhone className={classes.iconPhone} />
            <IconMobile className={classes.iconMobile} />
          </div>
        </Grid>
      </Grid>
    </HomeLayout>
  )
}

export default HomePage